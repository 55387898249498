import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { injectIntl, moment } from 'helpers'
import ImgP from 'gatsby-image/withIEPolyfill'

import {
  Background,
  Content,
  Img,
  Flex,
  Layout,
  Section,
  Container,
  Box,
  Link,
  FormattedMessage,
  Case,
  BrandLogo,
} from 'components'

const PortfolioPage = ({ intl, data }) => {
  let { locale } = intl
  let { cases } = data
  return (
    <Layout>
      <Section>
        <Container>
          {cases &&
            cases.edges
              .filter(({ node }) => node.node_locale === locale)
              .map(
                ({
                  node: { id, slug, title, tags, date, logo, background },
                }) => (
                  <Case to={`/portfolio/${slug}`} key={id}>
                    <Content>
                      <Flex column>
                        {logo && (
                          <BrandLogo>
                            <ImgP fluid={logo.fluid} objectFit="contain" />
                          </BrandLogo>
                        )}
                        <h1>{title}</h1>
                        <h3>{moment(date).format('LL')}</h3>
                        <p>{tags}</p>
                      </Flex>
                    </Content>
                    {background && (
                      <Background dark>
                        <Img fluid={background.fluid} />
                      </Background>
                    )}
                  </Case>
                )
              )}
        </Container>
      </Section>
      <Section fullpage>
        <Container>
          <h1>
            <Link to="/technologies" underline>
              Technologies
            </Link>
            {` `} we used in our projects
          </h1>
        </Container>
      </Section>
    </Layout>
  )
}

export default injectIntl(PortfolioPage)

export const query = graphql`
  {
    cases: allContentfulCase(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          slug
          title
          tags
          date

          logo {
            id
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          background {
            id
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          descriptionImage {
            id
            fluid {
              ...GatsbyContentfulFluid
            }
          }

          node_locale
        }
      }
    }
  }
`
